import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import logo from './logo.png';
import logoWhite from './logoWhite.png'
import './App.css';
import serversData from './servers.js';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { comingSoonServers } from './servers.js';
import en from './lang/en.json';
import tr from './lang/tr.json';

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <MainContent />
    </Router>
  );
}

function MainContent() {
  const [servers, setServers] = useState([]);  // Boş array ile başlat
  const [totalPlayers, setTotalPlayers] = useState(0);
  const [displayedPlayers, setDisplayedPlayers] = useState(0);
  const [darkMode, setDarkMode] = useState(() => {
    if (typeof window !== 'undefined') {
      return window.matchMedia('(prefers-color-scheme: dark)').matches
    }
    return false
  });
  const [isLangDropdownOpen, setIsLangDropdownOpen] = useState(false);
  const [manualLang, setManualLang] = useState(() => {
    return localStorage.getItem('selectedLanguage') || null;
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isJoinModalOpen, setIsJoinModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedProjectType, setSelectedProjectType] = useState('existing');
  const [showBadge, setShowBadge] = useState(true);
  const [badgeAnimation, setBadgeAnimation] = useState(false);

  const navigate = useNavigate();
  const userRegion = navigator.language || navigator.userLanguage;
  const isTurkish = manualLang 
    ? manualLang === 'tr'
    : userRegion.startsWith('tr') || userRegion === 'tr-TR';
  
  const lang = isTurkish ? tr : en;
  const basePath = isTurkish ? '/tr' : '/en';

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => {
      setDarkMode(e.matches);
    };

    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
  }, []);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  useEffect(() => {
    // Önce statik sunucu verilerini yükle
    setServers(serversData);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (servers.length > 0) {
      const fetchPlayerCounts = async () => {
        try {
          const updatedServers = await Promise.all(
            servers.map(async (server) => {
              try {
                const corsProxy = 'https://api.allorigins.win/raw?url=';
                const response = await fetch(`${corsProxy}${encodeURIComponent(`https://api.mcsrvstat.us/3/${server.ip}`)}`);
                
                if (response.ok) {
                  const data = await response.json();
                  return { 
                    ...server, 
                    onlinePlayers: data.players?.online || 0 
                  };
                }

                return { ...server, onlinePlayers: 0 };
              } catch (error) {
                console.error(`Error fetching player count for ${server.name}:`, error);
                return { ...server, onlinePlayers: 0 };
              }
            })
          );

          setServers(updatedServers);
          const total = updatedServers.reduce((sum, server) => sum + (server.onlinePlayers || 0), 0);
          setTotalPlayers(total);
        } catch (error) {
          console.error('Error in fetchPlayerCounts:', error);
        }
      };

      fetchPlayerCounts();
    }
  }, [servers.length]);

  useEffect(() => {
    if (totalPlayers > 0) {
      const animationDuration = 2000; // 2 saniye
      const frameDuration = 1000 / 60; // 60 FPS
      const totalFrames = animationDuration / frameDuration;
      const incrementPerFrame = totalPlayers / totalFrames;

      let frame = 0;
      const counter = setInterval(() => {
        frame++;
        setDisplayedPlayers(Math.min(Math.floor(incrementPerFrame * frame), totalPlayers));

        if (frame >= totalFrames) {
          clearInterval(counter);
          setDisplayedPlayers(totalPlayers);
        }
      }, frameDuration);

      return () => clearInterval(counter);
    }
  }, [totalPlayers]);

  useEffect(() => {
    // Logo ve diğer resimleri preload et
    const preloadImages = () => {
      const images = [logo, logoWhite, ...servers.map(server => require(`./${server.logo}`))];
      images.forEach(src => {
        const img = new Image();
        img.src = src;
      });
    };

    preloadImages();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isDropdownOpen && !event.target.closest('.status-dropdown')) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);

  const serversRef = useRef(null);

  const scrollToServers = () => {
    serversRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 8000,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  };

  const handleBadgeClick = () => {
    setBadgeAnimation(true);
    setTimeout(() => {
      setShowBadge(false);
      setIsJoinModalOpen(true);
    }, 500);
  };

  const AppContent = () => (
    <div className="App">
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div className="flex justify-between items-center p-4 bg-secondary-light dark:bg-secondary-dark">
          <div className="flex-1 flex justify-center">
            <img src={darkMode ? logoWhite : logo} alt="logo" className="max-w-[150px] h-auto" />
          </div>
          
          <div className="absolute right-4">
            <div className="lang-selector">
              <button 
                className="lang-button"
                onClick={() => setIsLangDropdownOpen(!isLangDropdownOpen)}
              >
                {isTurkish ? 'TR' : 'EN'}
                <span className="ml-2">
                  <i className="fas fa-globe"></i>
                </span>
              </button>
              {isLangDropdownOpen && (
                <div className="lang-dropdown">
                  <button 
                    className="lang-option"
                    onClick={() => {
                      localStorage.setItem('selectedLanguage', 'en');
                      setManualLang('en');
                      navigate('/en');
                      setIsLangDropdownOpen(false);
                    }}
                  >
                    <span className="flag">🇬🇧</span>
                    <span className="lang-name">English</span>
                  </button>
                  <button 
                    className="lang-option"
                    onClick={() => {
                      localStorage.setItem('selectedLanguage', 'tr');
                      setManualLang('tr');
                      navigate('/tr');
                      setIsLangDropdownOpen(false);
                    }}
                  >
                    <span className="flag">🇹🇷</span>
                    <span className="lang-name">Türkçe</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="hero container">
        <div className="hero-bg">
          <h2 className="text-text-light dark:text-text-dark">{lang.welcome}</h2>
          <h1 className="text-text-light dark:text-text-dark">
            <span>{displayedPlayers}</span> {lang.players}
          </h1>
          <button className="text-black dark:text-white" onClick={scrollToServers}>
            {lang.viewServers}
          </button>
        </div>
      </div>

      <div className="logo-carousel-container my-20">
        <Slider {...settings}>
          {servers.map((server, index) => (
            <div key={index} className="px-4">
              <img 
                src={require(`./${server.logo}`)}
                alt={server.name}
                className="h-16 w-auto mx-auto filter grayscale opacity-50 hover:grayscale-0 hover:opacity-100 transition-all duration-300"
              />
            </div>
          ))}
        </Slider>
      </div>

      <div className="aboutUs container my-20">
        <h2 className="text-4xl font-bold mb-8 text-center text-text-light dark:text-text-dark">
          {lang.whoAreWe}
        </h2>
        
        <div className="max-w-3xl mx-auto space-y-6 text-lg text-text-light dark:text-text-dark">
          <p className="leading-relaxed">
            {lang.welcomeMessage}
          </p>

          <p className="leading-relaxed">
            {lang.communityMessage}
          </p>

          <p className="leading-relaxed">
            {lang.joinMessage}
          </p>
        </div>
      </div>

      <div id="servers" className="servers container" ref={serversRef}>
        <h2>{lang.ourServers}</h2>

        <div className="server-cards">
          {servers.map((server, index) => (
            <div key={index} className="server-card">
              <div 
                className="server-img-bg"
                style={{ backgroundColor: server.logoBgColor }}
              >
                <img 
                  src={require(`/${server.logo}`)} 
                  alt={`${server.name} logo`}
                />
              </div>
              
              <div className="server-info">
                <div className="server-header">
                  <h3>{server.name}</h3>
                  <div className="server-social">
                    <a 
                      href={server.social.discord} 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-discord"></i>
                    </a>
                    <a 
                      href={server.social.instagram} 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </div>
                </div>
                
                <p>{isTurkish ? server.description_tr : server.description}</p>
                
                <div className="server-footer">
                  <a 
                    href={server.webSite} 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    <button
                      style={{ 
                        backgroundColor: server.buttonColor,
                        color: server.textColor 
                      }}
                    >
                      {lang.play}
                    </button>
                  </a>
                  <div className="server-players">
                    <span>{server.onlinePlayers} </span>
                     {lang.players}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="coming-soon container">
        <h2>{lang.comingSoon}</h2>
        <div className="coming-soon-cards grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {comingSoonServers.map((server, index) => (
            <div 
              key={index} 
              className="coming-soon-card w-full"
              style={{ 
                backgroundColor: darkMode ? '#333' : '#fff',
                color: darkMode ? '#f9f9f9' : '#333',
                boxShadow: darkMode ? 'none' : '0 2px 4px rgba(0,0,0,0.1)'
              }}
            >
              <img 
                src={require(`./${server.logo}`)} 
                alt={`${server.name} logo`}
              />
              <h3>{server.name}</h3>
              <p>{server.description}</p>
              <div className="expected-date">
                {lang.expectedDate} {server.expectedDate}
              </div>
              <button
                style={{ 
                  backgroundColor: '#FFD700',
                  color: '#333'
                }}
                disabled
              >
                {lang.comingSoon}
              </button>
            </div>
          ))}
          
          {/* Join Us Card */}
          <div 
            className="coming-soon-card w-full cursor-pointer hover:transform hover:scale-105 transition-all"
            onClick={() => setIsJoinModalOpen(true)}
            style={{ 
              backgroundColor: darkMode ? '#333' : '#fff',
              color: darkMode ? '#f9f9f9' : '#333',
              boxShadow: darkMode ? 'none' : '0 2px 4px rgba(0,0,0,0.1)'
            }}
          >
            <div className="w-40 h-40 mx-auto mb-6 rounded-full bg-primary flex items-center justify-center">
              <i className="fas fa-plus text-4xl text-white"></i>
            </div>
            <h3>{lang.joinUs}</h3>
            <p>{lang.joinUsDescription}</p>
            <div className="expected-date">
              &nbsp;
            </div>
            <button
              style={{ 
                backgroundColor: '#FFD700',
                color: '#333',
                cursor: 'pointer',
                border: '1',
                opacity: '1'
              }}
            >
              {lang.joinNow}
            </button>
          </div>
        </div>
        
        {/* Modal */}
        {isJoinModalOpen && (
          <JoinModal 
            onClose={() => setIsJoinModalOpen(false)} 
            lang={lang}
            darkMode={darkMode}
          />
        )}
      </div>

      <footer className="bg-secondary-dark text-white py-10">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center gap-6 mb-8">
            <div className="footer-logo">
              <img src={logoWhite} alt="Logo" className="h-12" />
            </div>
            
            <ul className="flex gap-6">
              <li>
                <a href="/" className="hover:text-primary transition-colors">
                  Home
                </a>
              </li>
              <li>
                <a href="#servers" className="hover:text-primary transition-colors">
                  Servers
                </a>
              </li>
            </ul>
            
            <div className="flex gap-4">
              <a href="https://discord.gg/lime" 
                 className="text-white hover:text-primary transition-colors"
                 target="_blank" 
                 rel="noopener noreferrer">
                <i className="fab fa-discord text-2xl"></i>
              </a>
              {/* Diğer sosyal medya ikonları */}
            </div>
          </div>
          
          <div className="text-center pt-6 border-t border-gray-700">
            <p className="text-sm">
              &copy; 2024 LimeNW. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );

  return (
    <Routes>
      <Route path="/" element={<Navigate to={basePath} replace />} />
      <Route 
        path="/en" 
        element={
          isLoading ? <div>Loading...</div> : <AppContent />
        } 
      />
      <Route 
        path="/tr" 
        element={
          isLoading ? <div>Loading...</div> : <AppContent />
        } 
      />
      <Route path="*" element={<Navigate to={basePath} replace />} />
    </Routes>
  );
}

const JoinModal = ({ onClose, lang, darkMode }) => {
  const [formData, setFormData] = useState({
    projectType: 'existing',
    serverName: '',
    expectations: '',
    aboutYou: '',
    email: '',
    discord: '',
    yourExpectations: ''
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedProjectType, setSelectedProjectType] = useState('existing');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.serverName || !formData.email || !formData.discord) {
        alert(lang.fillRequiredFields);
        return;
      }

      // Google Analytics event'i gönder
      if (window.gtag) {
        window.gtag('event', 'project_submission', {
          'event_category': 'Form',
          'event_label': formData.serverName,
          'project_type': formData.projectType,
          'server_name': formData.serverName
        });
      }

      // Discord webhook URL
      const webhookUrl = "https://discord.com/api/webhooks/1335378320946364508/q_4ixY4b_f7ID5a0_nXbpidB7CnYgjG9pL-GOyEVmhfjpD6C1yF19Vao6Z9jItG1pRph";

      // Discord mesaj formatı
      const message = {
        embeds: [{
          title: "Yeni Sunucu Başvurusu",
          color: 0xFFD700,
          fields: [
            {
              name: "Proje Durumu",
              value: formData.projectType === 'existing' ? 'Şu An Yayında' :
                     formData.projectType === 'planned' ? 'Planlanıyor' : 'Yapım Aşamasında',
              inline: true
            },
            {
              name: "Sunucu Adı",
              value: formData.serverName,
              inline: true
            },
            {
              name: "Discord",
              value: formData.discord,
              inline: true
            },
            {
              name: "Email",
              value: formData.email,
              inline: true
            },
            {
              name: "Hedefler & Beklentiler",
              value: formData.expectations || "Belirtilmedi"
            },
            {
              name: "Hakkında",
              value: formData.aboutYou || "Belirtilmedi"
            },
            {
              name: "Bizden Beklentiler",
              value: formData.yourExpectations || "Belirtilmedi"
            }
          ],
          timestamp: new Date().toISOString()
        }]
      };

      const response = await fetch(webhookUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(message)
      });

      if (!response.ok) {
        throw new Error('Webhook request failed');
      }

      // Başarılı submission event'i
      if (window.gtag) {
        window.gtag('event', 'project_submission_success', {
          'event_category': 'Form',
          'event_label': formData.serverName
        });
      }

      alert(lang.submitSuccess);
      onClose();

    } catch (error) {
      // Hata durumunda event gönder
      if (window.gtag) {
        window.gtag('event', 'project_submission_error', {
          'event_category': 'Form',
          'event_label': error.message
        });
      }

      console.error('Form submission error:', error);
      alert(lang.submitError);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className={`${darkMode ? 'bg-[#2c2c2c]' : 'bg-white'} rounded-lg p-4 max-w-2xl w-full max-h-[90vh] overflow-y-auto`}>
        <div className="flex justify-between items-center mb-2">
          <div className="flex items-center justify-between w-full">
            <h2 className={`text-2xl font-bold ${darkMode ? 'text-[#f9f9f9]' : 'text-gray-900'}`}>
              {lang.joinUsTitle}
            </h2>
            <button
              onClick={onClose}
              className={`${darkMode ? 'text-gray-400 hover:text-gray-200' : 'text-gray-500 hover:text-gray-700'} p-0.5 ml-4`}
            >
              <i className="fas fa-times text-base"></i>
            </button>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6" autoComplete="off">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className={`block text-sm font-medium ${
                darkMode ? 'text-[#f9f9f9]' : 'text-gray-900'
              } mb-1`}>
                {lang.projectType}
              </label>
              <div className="relative status-dropdown">
                <button
                  type="button"
                  className={`w-full px-4 py-2 text-left border ${
                    darkMode 
                      ? 'bg-[#2c2c2c] text-[#f9f9f9] border-gray-600' 
                      : 'bg-white text-gray-900 border-gray-300'
                  } rounded-md focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent`}
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  <span className="block truncate">
                    {selectedProjectType === 'existing' && lang.projectTypeExisting}
                    {selectedProjectType === 'planned' && lang.projectTypePlanned}
                    {selectedProjectType === 'inProgress' && lang.projectTypeInProgress}
                  </span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                    <i className={`fas fa-chevron-down transition-transform duration-200 ${isDropdownOpen ? 'transform rotate-180' : ''}`}></i>
                  </span>
                </button>
                
                {isDropdownOpen && (
                  <div className={`absolute z-10 w-full mt-1 ${
                    darkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-300'
                  } border rounded-md shadow-lg`}>
                    <ul className="py-1 max-h-60 overflow-auto">
                      <li
                        className={`px-4 py-2 cursor-pointer ${
                          darkMode 
                            ? 'text-[#f9f9f9] hover:bg-gray-800' 
                            : 'text-gray-900 hover:bg-gray-100'
                        }`}
                        onClick={() => {
                          setFormData(prev => ({ ...prev, projectType: 'existing' }));
                          setSelectedProjectType('existing');
                          setIsDropdownOpen(false);
                        }}
                      >
                        {lang.projectTypeExisting}
                      </li>
                      <li
                        className={`px-4 py-2 cursor-pointer ${
                          darkMode 
                            ? 'text-[#f9f9f9] hover:bg-gray-800' 
                            : 'text-gray-900 hover:bg-gray-100'
                        }`}
                        onClick={() => {
                          setFormData(prev => ({ ...prev, projectType: 'planned' }));
                          setSelectedProjectType('planned');
                          setIsDropdownOpen(false);
                        }}
                      >
                        {lang.projectTypePlanned}
                      </li>
                      <li
                        className={`px-4 py-2 cursor-pointer ${
                          darkMode 
                            ? 'text-[#f9f9f9] hover:bg-gray-800' 
                            : 'text-gray-900 hover:bg-gray-100'
                        }`}
                        onClick={() => {
                          setFormData(prev => ({ ...prev, projectType: 'inProgress' }));
                          setSelectedProjectType('inProgress');
                          setIsDropdownOpen(false);
                        }}
                      >
                        {lang.projectTypeInProgress}
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>

            <div>
              <label className={`block text-sm font-medium ${
                darkMode ? 'text-[#f9f9f9]' : 'text-gray-900'
              } mb-1`}>
                {lang.serverName}
              </label>
              <input
                type="text"
                name="serverName"
                value={formData.serverName}
                onChange={handleChange}
                className={`w-full px-3 py-2 rounded-md border ${
                  darkMode 
                    ? 'bg-[#2c2c2c] text-[#f9f9f9] border-gray-600 placeholder-gray-400'
                    : 'bg-white text-gray-900 border-gray-300 placeholder-gray-400'
                } focus:ring-2 focus:ring-primary focus:border-transparent`}
                placeholder={lang.serverNamePlaceholder}
              />
            </div>
          </div>

          <div>
            <label className={`block text-sm font-medium ${
              darkMode ? 'text-[#f9f9f9]' : 'text-gray-900'
            } mb-1`}>
              {lang.expectations}
            </label>
            <textarea
              name="expectations"
              value={formData.expectations}
              onChange={handleChange}
              className={`w-full px-3 py-2 rounded-md border ${
                darkMode 
                  ? 'bg-[#2c2c2c] text-[#f9f9f9] border-gray-600 placeholder-gray-400'
                  : 'bg-white text-gray-900 border-gray-300 placeholder-gray-400'
              } h-20 focus:ring-2 focus:ring-primary focus:border-transparent resize-none`}
              placeholder={lang.expectationsPlaceholder}
            ></textarea>
          </div>

          <div>
            <label className={`block text-sm font-medium ${
              darkMode ? 'text-[#f9f9f9]' : 'text-gray-900'
            } mb-1`}>
              {lang.aboutYou}
            </label>
            <textarea
              name="aboutYou"
              value={formData.aboutYou}
              onChange={handleChange}
              className={`w-full px-3 py-2 rounded-md border ${
                darkMode 
                  ? 'bg-[#2c2c2c] text-[#f9f9f9] border-gray-600 placeholder-gray-400'
                  : 'bg-white text-gray-900 border-gray-300 placeholder-gray-400'
              } h-20 focus:ring-2 focus:ring-primary focus:border-transparent resize-none`}
              placeholder={lang.aboutYouPlaceholder}
            ></textarea>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className={`block text-sm font-medium ${
                darkMode ? 'text-[#f9f9f9]' : 'text-gray-900'
              } mb-1`}>
                {lang.email}
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className={`w-full px-3 py-2 rounded-md border ${
                  darkMode 
                    ? 'bg-[#2c2c2c] text-[#f9f9f9] border-gray-600 placeholder-gray-400'
                    : 'bg-white text-gray-900 border-gray-300 placeholder-gray-400'
                } focus:ring-2 focus:ring-primary focus:border-transparent`}
                placeholder={lang.emailPlaceholder}
              />
            </div>

            <div>
              <label className={`block text-sm font-medium ${
                darkMode ? 'text-[#f9f9f9]' : 'text-gray-900'
              } mb-1`}>
                {lang.discordTag}
              </label>
              <input
                type="text"
                name="discord"
                value={formData.discord}
                onChange={handleChange}
                className={`w-full px-3 py-2 rounded-md border ${
                  darkMode 
                    ? 'bg-[#2c2c2c] text-[#f9f9f9] border-gray-600 placeholder-gray-400'
                    : 'bg-white text-gray-900 border-gray-300 placeholder-gray-400'
                } focus:ring-2 focus:ring-primary focus:border-transparent`}
                placeholder={lang.discordPlaceholder}
              />
            </div>
          </div>

          <div>
            <label className={`block text-sm font-medium ${
              darkMode ? 'text-[#f9f9f9]' : 'text-gray-900'
            } mb-1`}>
              {lang.yourExpectations}
            </label>
            <textarea
              name="yourExpectations"
              value={formData.yourExpectations}
              onChange={handleChange}
              className={`w-full px-3 py-2 rounded-md border ${
                darkMode 
                  ? 'bg-[#2c2c2c] text-[#f9f9f9] border-gray-600 placeholder-gray-400'
                  : 'bg-white text-gray-900 border-gray-300 placeholder-gray-400'
              } h-20 focus:ring-2 focus:ring-primary focus:border-transparent resize-none`}
              placeholder={lang.yourExpectationsPlaceholder}
            ></textarea>
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              className="w-full px-6 py-2 bg-primary text-white rounded-md hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
            >
              {lang.submit}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default App;
