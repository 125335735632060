const servers = [
  {
    "name": "CraftLime",
    "ip": "mc.craftlime.net",
    "logo": "swLogo.png",
    "description": "Founded in 2022, CraftLime has quickly risen to become the #1 Towny server, offering a thriving and dynamic community for players who love building, trading, and creating towns.",
    "description_tr": "2022'de kurulan CraftLime, kasaba insa etmeyi, ticaret yapmayı ve topluluk olusturmayı seven oyuncular için hızla en iyi Towny sunucusu haline geldi.",
    "social": {
      "discord": "https://discord.gg/lime",
      "instagram": "https://instagram.com/craftlimenw"
    },
    "logoBgColor": "#FFD700",
    "buttonColor": "#FFD700",
    "textColor": "#000000",
    "webSite": "https://craftlime.net"
  },
  {
    "name": "LuneCraft",
    "ip": "mc.lunecraft.net",
    "logo": "lune.svg",
    "description": "Acquired in 2024, LuneCraft delivers a truly authentic Towny experience, offering a thriving and dynamic community for players who enjoy creating, exploring, and expanding their towns.",
    "description_tr": "2024'te bünyemize dahil ettigimiz LuneCraft, kasaba kurmayı, kesfetmeyi ve topluluk olusturmaya sevenler için dinamik ve gelisen bir Towny deneyimi sunuyor.",
    "social": {
      "discord": "https://discord.gg/lunecraft",
      "instagram": "https://instagram.com/lunecraftmc"
    },
    "logoBgColor": "#141827",
    "buttonColor": "#141827",
    "textColor": "#FFFFFF",
    "webSite": "https://lunecraft.net"
  },
  {
    "name": "Vanilya",
    "ip": "mc.vanilya.xyz",
    "logo": "vanilya.png",
    "description": "Acquired in 2024, Vanilya offers a unique blend of survival and Towny gameplay, providing a thriving and dynamic world for players who love to build, trade, and explore.",
    "description_tr": "2024'te satın alınan Vanilya, kasaba insa etmeyi, ticaret yapmayı ve kesfetmeyi seven oyuncular için dinamik ve gelisen bir hayatta kalma ve Towny deneyimi sunuyor.",
    "social": {
      "discord": "https://discord.gg/vanilyaxyz",
      "instagram": "https://instagram.com/vanilyaxyz"
    },
    "logoBgColor": "#40B0C0",
    "buttonColor": "#40B0C0",
    "textColor": "#FFFFFF",
    "webSite": "https://vanilya.xyz"
  },
  {
    "name": "LegendCraftTR",
    "ip": "play.legendcrafttr.com",
    "logo": "lgtr.png",
    "description": "Acquired in 2024, LegendCraftTR has been a key part of the Turkish Minecraft community, offering a thriving and dynamic world for players who enjoy teamwork and adventure.",
    "description_tr": "2024'te satın alınan LegendCraftTR, Türk Minecraft toplulugunun önemli bir parçası olarak oyunculara dinamik ve gelisen bir dünya sunmaya devam ediyor.",
    "social": {
      "discord": "https://discord.gg/lgtr",
      "instagram": "#"
    },
    "logoBgColor": "#B73939",
    "buttonColor": "#B73939",
    "textColor": "#FFFFFF",
    "webSite": "https://legendcrafttr.net"
  },
  {
    "name": "Craftity",
    "ip": "mc.craftity.net",
    "logo": "craftity.png",
    "description": "Craftity is a Turkish Minecraft server with Java and Bedrock support, offering a thriving and dynamic multiplayer world with game modes like Towny, Skyblock, and more.",
    "description_tr": "CraftityNet, Java ve Bedrock destekleyen, Towny ve Skyblock gibi modlarla oyunculara dinamik ve gelisen bir çok oyunculu Minecraft dünyası sunan bir Türk sunucusudur.",
    "social": {
      "discord": "https://discord.gg/craftity",
      "instagram": "https://instagram.com/craftity"
    },
    "logoBgColor": "#9232e9",
    "buttonColor": "#9232e9",
    "textColor": "#ffffff",
    "webSite": "https://craftity.net"
  },
  {
    name: "CraftNovus",
    ip: "mc.craftnovus.com",
    logo: "craftnovus.png",
    description: "CraftNovus brings a fresh and innovative approach to multiplayer, offering a thriving and dynamic world where players can experience adventure and creativity.",
    "description_tr": "Yakında açılacak olan CraftNovus, macera ve yaratıcılıgı seven oyuncular için dinamik ve gelisen bir dünya sunan yenilikçi bir çok oyunculu sunucu deneyimi sunuyor.",
    "social": {
      "discord": "https://discord.gg/novus",
      "instagram": "https://instagram.com/craftnovus"
    },
    buttonColor: "#6B4EE6",
    textColor: "#FFFFFF",
    logoBgColor: "#2D1B69"
  }
];

export const comingSoonServers = [
  {
    name: "iBandee",
    ip: "mc.ibandee.com",
    logo: "ibandee.png",
    description: "It's Cominggg!",
    expectedDate: "February 2025",
    buttonColor: "#6B4EE6",
    textColor: "#FFFFFF",
    logoBgColor: "#2D1B69"
  },
  {
    "name": "MineLama",
    "ip": "play.minelama.net",
    "logo": "Lama3.gif",
    "description": "It's cominggg!",
    expectedDate: "March 2025",
    "logoBgColor": "#8BB877",
    "buttonColor": "#8BB877",
    "textColor": "#000000",
    "webSite": "https://minelama.com"
  }
];

export default servers;
